<template>
  <HeroImageOverlay>
    <div class="max-w-xl my-auto">
      <h1 class="text-accent-lightest leading-tight">
        vancouver’s premier Luxury car service
      </h1>
      <p class="text-accent-lightest mt-2">
        Professional team of chauffeurs providing exceptional services.
      </p>
      <router-link to="/cars" class="btn btn-xl mt-8">View cars</router-link>
    </div>
  </HeroImageOverlay>

  <!-- Split -->
  <Split
    title="what we do"
    description="
    DriveUs is Vancouver’s Premier Luxury Chauffeured Car Service.  We offer the latest and most luxurious limousines driven by professionally trained Chauffeurs. In fact we hire all our Chauffeurs from the Hospitality Industry like the Fairmont and Shangri La Hotel Chains.  Our clients include Grammy Award Winning Artists, CEO’s, Super Bowl MVP’s, Heads of State and Soccer Moms.
    <br/><br/>
    Call us today and set up an account and see why so many people have made the switch to DriveUs."
    :flip="true"
    :dark="true"
  >
    <img
      class="object-cover"
      src="@/assets/images/index.jpg"
      alt="mercedes s560 driving on road"
    />
  </Split>

  <FeatureGridList
    title="Business Class"
    description="Featuring the most luxurious chauffeured automobiles Vancouver has to offer."
    :features="features"
  />
  <Container>
    <div class="border-t border-accent-darkest pt-12">
      <div class="max-w-3xl mx-auto text-center">
        <h2 class="mb-4">Driven to exceed your expectation</h2>
        <p
          class="text-lg text-gray-900 font-light mx-auto max-w-md md:max-w-full"
        >
          Professionally chauffeured luxury limousines to transport you safely,
          <br class="hidden md:block" />confidentially and in grand style.
        </p>
      </div>
      <div class="mt-6 grid md:grid-cols-3 gap-8">
        <!-- <div class="text-center flex flex-col items-center">
          <div class="w-full max-w-lg">
            <div class="aspect-h-3 aspect-w-4 relative">
          <img
            src="@/assets/images/merc.jpg"
            alt="merc"
           
                class=" object-cover py-6 absolute inset-0"
              />
            </div>
          </div>
          <p class="font-medium text-lg">2022 Mercedes Benz S580</p>
          <p class="mt-1 font-light">4 Passenger</p>
          <router-link
            to="/cars/mercedes-benz-S580"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div> -->
        <div class="text-center flex flex-col items-center">
          
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
            src="/cars-img/escalade-ev/index.jpg"
            alt="merc"
            class="object-cover w-full h-full absolute inset-0   py-2"
          />
          </div>
          </div>
          <p class="font-medium text-lg">2025 Cadillac Escalade IQ EV</p>
          <p class="mt-1 font-light">6 Passenger</p>
          <router-link
            to="/cars/cadillac-escalade-ev"
            class="mt-4 btn btn-xl btn-light"
            >View this Car</router-link
          >
        </div>
        <!-- <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
              <img
                src="/cars-img/cadillac-ct5/index.jpg"
                alt="merc"
                class="object-contain w-full h-full absolute inset-0"
              />
            </div>
          </div>
          <p class="font-medium text-lg">2024 Cadillac CT5 V Series</p>
          <p class="mt-1 font-light">4 Passenger</p>
          <router-link to="/cars/cadillac-ct5" class="mt-4 btn btn-xl btn-light"
            >View this Car</router-link
          >
        </div> -->
        <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
          src="/cars-img/sprinter/index.jpg"
            alt="merc"
            class="object-contain mix-blend-multiply w-full h-full absolute inset-0"
          />
          </div>
          </div>
          <p class="font-medium text-lg">2024 Mercedes Benz AWD Sprinter Van</p>
          <p class="mt-1 font-light">12 Passenger</p>
          <router-link
            to="/cars/2024-sprinter"
            class="mt-4 btn btn-xl btn-light"
            >View this Car</router-link
          >
        </div>
        <div class="text-center flex flex-col items-center">
          <div class="w-full max-w-lg">
            <div class="aspect-h-3 aspect-w-4 relative">
              <img
                src="@/assets/images/caddy-left.jpg"
                alt="caddy"
                class="mix-blend-multiply object-cover py-6 absolute inset-0"
              />
            </div>
          </div>
          <p class="font-medium text-lg">
            2023 Cadillac Escalade ESV Platinum Sport
          </p>
          <p class="mt-1 font-light">6 Passenger</p>
          <router-link
            to="/cars/cadillac-escalade-ESV"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div>
        <!-- <div class="text-center flex flex-col items-center">
          <div class="w-full max-w-lg">
            <div class="aspect-h-3 aspect-w-4 relative">
              <img
                src="/cars-img/denali/index-thumb.jpg"
                alt="denali"
                class="object-cover w-full h-full absolute inset-0 py-2"
              />
            </div>
          </div>
          <p class="font-medium text-lg">2023 GMC Denali XL Ultimate Edition</p>
          <p class="mt-1 font-light">6 Passenger</p>
          <router-link
            to="/cars/gmc-denali-ultimate"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div> -->

        <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
            src="/cars-img/sprinter/index.jpg"
            alt="caddy"
            class="object-cover mix-blend-multiply w-full h-full absolute inset-0   py-2"
          />
          </div>
          </div>
          <p class="font-medium text-lg">
            2022 Mercedes Benz Sprinter Van
          </p>
          <p class="mt-1 font-light">12 Passenger</p>
          <router-link
            to="/cars/mercedes-benz-sprinter"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div>

        <!-- <div class="text-center flex flex-col items-center">
          <div class="w-full">
            <div class="aspect-h-3 aspect-w-4 relative max-w-lg">
          <img
            src="/cars-img/chevrolet/index-thumb.jpg"
            alt="caddy"
            class="object-cover w-full h-full absolute inset-0   py-2"
          />
          </div>
          </div>
          <p class="font-medium text-lg">2023 Chevrolet Suburban RST</p>
          <p class="mt-1 font-light">6 Passenger</p>
          <router-link
            to="/cars/chevrolet-suburban-rst"
            class="mt-4 btn btn-xl btn-light"
            >View this car</router-link
          >
        </div> -->
      </div>
    </div>
  </Container>

  <FullSplit class="bg-accent-darkest text-accent-lightest">
    <template v-slot:left>
      <h2 class="md:text-5xl text-white mb-5">guaranteed on time pick up</h2>
      <p>
        Professionally chauffeured luxury limousines to transport you safely,
        confidentially and in grand style.
        <br /><br />
        We guarantee on time pick up or it’s free.
        <br />
        * Certain restrictions apply.
      </p>
    </template>
    <template v-slot:right>
      <img
        class="w-full h-full object-cover"
        src="/images/cta.jpg"
        alt=""
      />
    </template>
  </FullSplit>

  <Container>
    <h2 class="text-center max-w-2xl mx-auto mb-16">
      Our businesss is your comfort
    </h2>
    <div class="mt-6 grid lg:grid-cols-3 gap-8">
      <div class="text-center flex flex-col items-center">
        <img
          src="@/assets/images/look-phone.jpg"
          alt="man exiting car on phone"
          class="max-w-lg mb-4 h-64 object-cover w-full"
        />
        <p class="font-medium text-lg">Executive Transportation</p>
        <p class="mt-1">
          Allow us to transport your employees and clients in luxurious comfort
          you can trust.
        </p>
      </div>
      <div class="text-center flex flex-col items-center">
        <img
          src="/images/cta-2.jpg"
          alt="merc from behind"
          class="max-w-lg mb-4 h-64 object-cover w-full"
        />
        <p class="font-medium text-lg">Luxury Vancouver Airport Transfers</p>
        <p class="mt-1">
          Relax and travel in style with our standard meet and greet at the
          airport or if you are departing from the greater Vancouver area, we
          will get you there on time and at ease.
        </p>
      </div>
      <div class="text-center flex flex-col items-center">
        <img
          src="@/assets/images/door-open.jpg"
          alt="chauffeur opening door"
          class="max-w-lg mb-4 h-64 object-cover w-full"
        />
        <p class="font-medium text-lg">Chauffeured Tours</p>
        <p class="mt-1">
          Explore the best that Vancouver and Whistler have to offer with our
          knowledgeable local chauffeurs.
        </p>
      </div>
    </div>
  </Container>

  <Container class="bg-accent-dark">
    <div class="text-center mb-4">
      <h2 class="text-black">You'll love drive us</h2>
      <p class="font-light">See what our customers are saying.</p>
      <div class="flex items-center justify-between">
        <div class="hidden md:inline-flex">
          <button @click="prev" class="btn btn-light btn-xl mr-2">
            <ArrowLeftIcon class="w-4 h-4 text-accent-darkest " />
          </button>
        </div>
        <div class="px-4 w-full">
          <div class="bg-white mt-10 w-full">
            <div class="p-6">
              <div
                class="p-8 w-full border border-accent-darkest text-center flex flex-col items-center"
              >
                <p class="font-medium text-lg">{{ reviews[curr].name }}</p>
                <p class="mt-1 max-w-4xl mx-auto">
                  {{ reviews[curr].description }}
                </p>
                <div class="flex items-center space-x-1 mt-4">
                  <StarIcon
                    class="w-5 h-5"
                    :class="reviews[curr].rating >= 1 && 'text-accent'"
                  />
                  <StarIcon
                    class="w-5 h-5"
                    :class="reviews[curr].rating >= 2 && 'text-accent'"
                  />
                  <StarIcon
                    class="w-5 h-5"
                    :class="reviews[curr].rating >= 3 && 'text-accent'"
                  />
                  <StarIcon
                    class="w-5 h-5"
                    :class="reviews[curr].rating >= 4 && 'text-accent'"
                  />
                  <StarIcon
                    class="w-5 h-5"
                    :class="reviews[curr].rating >= 5 && 'text-accent'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden md:inline-flex">
          <button
            @click="next"
            class="btn btn-light btn-xl ml-2 hidden md:inline-flex"
          >
            <ArrowRightIcon class="w-4 h-4 text-accent-darkest" />
          </button>
        </div>
      </div>
      <div class="flex justify-center items-center space-x-2 mt-6">
        <button
          v-for="(review, index) in reviews"
          @click="() => jumpTo(index)"
          :key="index"
          :class="`w-3 h-3 rounded-full ${
            index === curr ? 'bg-accent-darkest' : 'bg-gray-400'
          }`"
        ></button>
      </div>
      <div
        class="mt-12 border-t-2 border-accent-darkest pt-12 text-left flex items-center flex-col lg:flex-row"
      >
        <h3 class="mb-0 text-black">Enjoyed our service?</h3>
        <div
          class="flex flex-col items-center space-y-4 mt-4 lg:space-y-0 lg:flex-row lg:ml-auto lg:space-x-4"
        >
          <a
            href="https://www.google.com/search?source=hp&ei=34UNXc6dO4nbvgSeqabIDA&q=driveus&oq=driveus&gs_l=psy-ab.3..0i67j0i10i67j0i20i263j0i67j0i131j0l5.478.1217..1288...0.0..0.240.1400.2-6......0....1..gws-wiz.....0..35i39j0i3.ImhjlXMsBQQ#lrd=0x54867183068e2ea9:0xc2cf55ecad09f535,1,,,"
            target="_blank"
            class="btn btn-light btn-xl"
            >Review us on google</a
          >
          <a
            href="https://www.facebook.com/driveusvancouver/reviews/"
            target="_blank"
            class="btn btn-light btn-xl"
            >Review us on Facebook</a
          >
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import FullSplit from "../../components/layouts/FullSplit.vue";
import Container from "../../components/layouts/Container.vue";
import FeatureGridList from "../../components/layouts/features/FeatureGridList.vue";
import HeroImageOverlay from "../../components/layouts/hero/HeroImageOverlay.vue";
import Header from "@/components/base/Header";
import Split from "@/components/layouts/Split.vue";
import { StarIcon, ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/solid";

const getIconURL = (icon) => `/icons/${icon}.svg`;
const features = [
  {
    name: "Safety",
    icon: getIconURL("guard"),
    description:
      "Your comfort and safety are our priority - our licensed drivers are rigorously screened, knowledgeable and fully insured to get you to your final destination.",
  },
  {
    name: "Live GPS Tracking",
    icon: getIconURL("pin"),
    description: "All our limousines are equipped with Live GPS Tracking.",
  },
  {
    name: "4K HD Dash Cameras",
    icon: getIconURL("cam"),
    description:
      "All limousines have 4K HD Dash Cameras to ensure our chauffeurs follow our safety protocols.",
  },
  {
    name: "5G Wi-Fi + Power",
    icon: getIconURL("wifi"),
    description:
      "Complimentary 5G Wi-Fi by Rogers and 120V outlets for charging laptops.",
  },
  {
    name: "Dedicated Winter Tires",
    icon: getIconURL("cloud"),
    description:
      "All of our limousines are equipped with dedicated winter tires during the winter months.",
  },
  {
    name: "COVID-19",
    icon: getIconURL("sparkle"),
    description:
      "Our limousines are sanitized after each ride with scent-free wipes. Complimentary masks and hand sanitizer. No-touch thermometers. Mandatory screening of drivers daily to ensure your safety.",
  },
  {
    name: "No Hidden Fees",
    icon: getIconURL("money"),
    description:
      "We don't have an auto-gratuity of 15-20% or a fuel surcharge of 10-15%. No peak time surcharge and no cancellation fee if cancelled within 24 hours.",
  },
  {
    name: "Complimentary Fiji Water",
    icon: getIconURL("droplet"),
    description:
      "All of our limousines come stocked with complimentary bottles of Fiji water for your enjoyment.",
  },
];

const reviews = [
{
    name: "Michael L.",
    description: `I had never used a private car service before Drive Us, and I must say they left a very good impression and have set the standard. I requested both SUV's (which are both spacious and immaculately clean), to drive our wedding party and our parents. Both drivers were timely, communicative and very polite. If I had to imagine what having your own "Alfred" was like, I now know what it may have been like to be Bruce Wayne. Our driver Dorian, was great! I can't speak more highly of him. Thank you to Duke and the team at DriveUs for helping to make our wedding day a success!`,
    rating: 5,
  },
  {
    name: "Tien H.",
    description: `Impeccable cars! Have used Driveus several times for special events and each experience was top notch. Our driver is always early and very professional. Highly recommend!`,
    rating: 5,
  },
  {
    name: "Jason F.",
    description: `Fantastic service from beginning to end. I would recommend them to anyone.`,
    rating: 5,
  },
  {
    name: "Greg K.",
    description: `Drive US is leaps and bounds above the competition in Vancouver. While the rest are driving older vehicles that are run down and not well maintained, DrIve Us has current model year vehicles. Their drivers are always ahead of schedule and waiting , professional in every way. I only trust my family and business with Drive Us.`,
    rating: 5,
  },
  {
    name: "Dave M.",
    description: `I have used this service multiple times. Always a 1st class experience. Highly recommend them for airport pickup and drop off. Always on time.`,
    rating: 5,
  },
];

export default {
  components: {
    Header,
    Split,
    HeroImageOverlay,
    FeatureGridList,
    Container,
    FullSplit,
    StarIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
  },
  data() {
    return {
      features,
      reviews,
      curr: 0,
    };
  },
  methods: {
    next() {
      this.curr = (this.curr + 1) % this.reviews.length;
    },
    prev() {
      this.curr = (this.curr - 1 + this.reviews.length) % this.reviews.length;
    },
    jumpTo(index) {
      this.curr = index;
    },
  },
};
</script>
